.gallery-pane{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin-bottom: 2rem;
}
.gallery-pane4{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 98vw;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}

.gallery-pane6{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    width: 98vw;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 2rem;
}
.gallery-pane64 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
}
.gallery-pane__left_one {
    grid-column: 1 ;
    grid-row: 1;
    padding: 8px;
    cursor: pointer;
}

.gallery-pane__left_one > div {
    display:flex;
    flex-direction: column;
}

.gallery-pane__left_one > div img {
    padding-bottom: 0.5rem;
}


.gallery-pane__left_up_one {
    grid-column: 1 ;
    grid-row: 1;
    padding: 8px;
}
.gallery-pane__right_up_one {
    grid-column: 2/2 ;
    grid-row: 1;
    padding: 8px;
}
.gallery-pane__left_middle_one {
    grid-column: 1;
    grid-row: 2;
    padding: 8px;
}
.gallery-pane__right_middle_one {
    grid-column: 2;
    grid-row: 2;
    padding: 8px;
}
.gallery-pane__left_down_one {
    grid-column: 1;
    grid-row: 2/2;
    padding: 8px;
}
.gallery-pane__right_down_one {
    grid-column: 2/2 ;
    grid-row: 2/2;
    padding: 8px;
}
.gallery-pane__left_down_six {
    grid-column: 1;
    grid-row: 3/3;
    padding: 8px;
}
.gallery-pane__right_down_six {
    grid-column: 2/2 ;
    grid-row: 3/3;
    padding: 8px;
}

.gallery-pane__left_two {
    grid-column: 1 ;
    grid-row: 1 / span 2;
    padding: 8px;
    min-width: 924.8px;
}
.gallery-pane__left_two_bottom {
    grid-column: 1 ;
    grid-row: 2 / span 3;
    padding: 8px;
}



.gallery-pane2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 98vw;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}

.gallery-pane2 > div {
    display:flex;
    flex-direction: column;
}

.gallery-pane2 .gallery-pane_grid2_left_up {
    grid-column: 1;
    grid-row: 1;
    padding: 8px;
}
.gallery-pane2 .gallery-pane_grid2_right_up {
    grid-column: 2;
    padding: 8px;
}
.gallery-pane2 .gallery-pane_grid2_right_bottom{
    grid-column: 2;
    grid-row: 2;
    padding: 8px;
}
.gallery-pane2 .gallery-pane_grid2_left_bottom{
    grid-column: 1;
    grid-row: 2;
    padding: 8px;
}

.gallery-pane2 .gallery-pane_grid2_left_up .gallery-pane_dubble img{
    object-fit: cover;
    width: 100%;
    max-height:100%;
}

.gallery-pane__right_up_two {
    grid-column: 2/2;
    grid-row: 1/ span 2;
    padding: 8px;
}
.gallery-pane__right_up {
    grid-column: 2/2;
    grid-row: 1/1;
    padding: 8px;
 }
.gallery-pane__right_down{
    grid-column: 2/2;
    grid-row: 2/2;
    padding: 8px;
}
.gallery-pane__right_three_down{
    grid-column: 2/2;
    grid-row: 3/3;
    padding: 8px;
}



.gallery-pane4 .gallery-pane__left_up > div > img{
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}
.gallery-pane4 .gallery-pane__left_up {
    grid-column: 1;
    grid-row: 1;
    padding: 8px;
}
.gallery-pane4 .gallery-pane__left_up .gallery-pane_dubble{
    object-fit: cover;
    width: 100%;
    min-width: 924.8px;
    height:100%;
}
.gallery-pane4 .gallery-pane__right_up {
    grid-column: 2;
    grid-row: 1 / 3;
    padding: 8px;
}
.gallery-pane4 .gallery-pane__right_down {
    grid-column: 2;
    grid-row: 3;
    padding: 8px;
}
.gallery-pane4 .gallery-pane__right_down > div > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.gallery-pane4 .gallery-pane__left_down {
    grid-column: 1;
    grid-row: 2 / 4;
    padding: 8px;
}


.gallery-pane_dubble {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
}
.gallery-pane2, .gallery-pane4, .gallery-pane6 {
    max-width: 97vw;
    margin-bottom: 2rem;
}
.gallery-pane_dubble img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-pane_dubble_max{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.gallery-pane_dubble_max img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-pane_dubble_diff_upLeft {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.gallery-pane_dubble_diff_upLeft img {
    object-fit: cover;
    width: 100%;
}

.gallery-pane_dubble_diff  {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.gallery-pane_dubble_diff img {
    object-fit: cover;
    width: 100%;
}


.gallery-pane > div > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.gallery-pane6 > div > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-pane64 > div > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.gallery-pane4 > div > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-pane2 > div > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gallery-serie-full{
    padding: 8px 8px;
}

.gallery-serie-full > img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.serie-title{
    font-family: MontserratMedium;
    text-transform: uppercase;
    font-size: 12px;
    text-align: left;
    margin-left: 8px;
}

.mouseover{
    cursor: pointer;
}
@media only screen and (max-width: 1680px) {
    .gallery-pane__left_two {
        min-width: auto;
    }
    .gallery-pane4 .gallery-pane__left_up .gallery-pane_dubble, .gallery-pane_dubble_diff{
        object-fit: cover;
        min-width: auto;
    }
}

@media only screen and (max-width: 600px) {
    .gallery-pane > div {
        padding: 2px;
    }
    .gallery-pane_dubble, .gallery-pane_dubble_max {
        overflow: hidden;
    }
    .gallery-pane_dubble {
        max-height: 158px;
    }
    .gallery-pane_dubble_max {
        max-height: 150px;
    }
    .gallery-pane_dubble_diff {
        max-height: 135px;
    }
    .gallery-pane6, .gallery-pane4, .gallery-pane2{
        max-width: 91vw;
    }
    .gallery-pane_dubble_diff_upLeft {
        height: 100%;
    }
    .gallery-pane6 > div {
        padding: 2px !important;
    }
    .gallery-pane4 > div {
        padding: 2px !important;
    }
    .gallery-pane2 > div {
        padding: 2px !important;
    }
    .gallery-pane64 > div {
        padding: 2px;
    }
    .gallery-serie-full{
        padding: 4px 4px;
    }

}
