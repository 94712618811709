.about {
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    flex-direction: row;
}
.about__info h4 {
    margin-top: -1.2rem;
    text-transform: uppercase;
}
.about__contact{
    text-transform: uppercase;
    line-height: 1.8rem;
    letter-spacing: 1px;
}
.about__address{
    margin-top: 2rem;
    font-family: MontserratLight;
}
.about__photo {
    width: 45%;
    height: 100%;
    background-image: url('../images/valeri.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.about__info {
    width: 55%;
    display:grid;
    place-items: center;
    margin: 0 4rem;
}
.about__me{
    font-family: MontserratLight;
    line-height: 1.8rem;
    letter-spacing: 1px;
    margin-top: 4rem;
}

.series {
    margin-bottom: 3rem;
}

main {
    margin-top: -5px;
}

@media only screen and (max-width: 600px) {
    .about {
        flex-direction: column;
        height: unset;
    }
    .about__photo{
        height: 200px;
        width:100%
    }
    .about__info {
        width: 100%;
        margin: 0;
    }
    .about__me {
        margin-top: 0.5rem;
    }
    .about__info h4 {
        margin-top: 1.2rem;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 1250px) {
    .about__info h4 {
        margin-top: 3rem;
    }

    .about__me {
        margin-top: 1rem;
    }
}
