.contact {
    height: calc(100vh - 80px);
    width: 100%;
    display: grid;
    place-items: center;
}
.contact__title > h4 {
    margin-top: -1.8rem;
}
.contact__title{
    text-transform: uppercase;
    line-height: 1.8rem;
    letter-spacing: 1px;
}
.about__address{
    margin-top: 2rem;
    font-family: MontserratLight;
}
