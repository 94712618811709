.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display:flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: relative;
  padding: 0 8px;
}
.App-header__logo {
  width: 300px;
  height: 90px;
  margin-right: 10px;
  background-image: url("./images/logo_valeri.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: -40px;
  margin-top: -25px;
  z-index: 999;
}
.icon{
  height:30px;
  width:30px;
  margin-left: 15px;
  cursor: pointer;
}
.logo{
  height: 80px;
  position: absolute;
  top:  -20px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main {
  width:100%;
}

#menuToggle
{
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle span
{
  display: block;
  width: 20px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;

  background: #000;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0;

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  opacity 0.55s ease;
}

#menuToggle span
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}
#menuToggle input:checked ~ span:nth-last-child(1)
{
  transform: rotate(-45deg) translate(2px, -0px);
}
#menuToggle input:checked ~ span:nth-last-child(2)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.1, 0.1);
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  transform: rotate(45deg) translate(1px, -3px);
}

#menuToggle input:checked ~ ul
{
  transform: none;
}


.menu {
  position: absolute;
  left: 0;
  z-index: 999;
  background-color: #fff;
  bottom: -141px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.show {
  left:0;
}

.hidden {
  left: -140px;
}

.menu > ul > li:nth-child(3) {
  margin-bottom: 30px;
}

.menu > ul {
  list-style: none;
  text-align: left;
  padding: 10px;
}

.menu > ul > li {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  font-family: MontserratMedium;
}

.menu > ul > li:hover {
  text-decoration: underline;
}

.tools{
  display: flex;
  width:100px;
  position: relative;
  align-items: center;
}

.title-and-credit{
  display:flex;
  flex-direction: column;
  align-items: start;
}

.title-and-credit p{
  margin: 0 0.5rem 1rem 0;
  font-size: 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .App-header__logo {
    width: 150px;
    height: 45px;
    margin-right: 10px;
    background-image: url("./images/logo_valeri.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -20px;
    margin-top: -20px;
    z-index: 999;
  }
  main {
    margin-top: -15px;
  }
}

.serie > div > img {
  width: 75%;
  height: 100%;
  object-fit: contain;
}
