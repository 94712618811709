@font-face {
  font-family: MontserratMedium;
  src: url(fonts/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: MontserratLight;
  src: url(fonts/Montserrat/Montserrat-Light.ttf);
}
@font-face {
  font-family: MontserratBold;
  src: url(fonts/Montserrat/Montserrat-Bold.ttf);
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0 16px;
  font-family: MontserratMedium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  color: #000;
  text-decoration: none;
}

h4 {
  font-family: MontserratBold;
}
